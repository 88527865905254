<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <div>
            <b-form  @submit.prevent="fetch(1)">
              <b-row>
                <b-col>
                  <b-form-input class="mr-1" v-model="filter.q" placeholder="Recherche par Marque, Modèle, Immat, Client..." />
                </b-col>
<!--                <b-col v-if="$can('voir-stock-concession', 'stock') || $can('voir-stock-global', 'stock')">-->
<!--                  <v-select :reduce="item => item.id" v-model="filter.concession_id" label="libelle" :options="$store.state.initialConfigModule.initialState.concessions" placeholder="Rechercher par concession"></v-select>-->
<!--                </b-col>-->
<!--                <b-col v-if="$can('voir-stock-concession', 'stock') || $can('voir-stock-site', 'stock') || $can('voir-stock-global', 'stock')">-->
<!--                  <v-select :reduce="item => item.id" v-model="filter.site_id" label="libelle" :options="$store.state.initialConfigModule.initialState.sites" placeholder="Rechercher par site"></v-select>-->
<!--                </b-col>-->
                <b-col md="auto">
                  <b-button type="submit"  variant="primary">Recherche</b-button>
                  <b-button v-if="$can('exporter-gestion-stock', 'vehicule')"  @click="exportHandler" :disabled="pendindExport" class="ml-1"  variant="primary">
                    <b-spinner small v-if="pendindExport"></b-spinner>
                    Exporter
                  </b-button>
                </b-col>
              </b-row>
              
              
              
              
              
              
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay :show="is_loading">
          <b-card

              no-body
          >
            <div  class="m-2 d-flex justify-content-between">
              <b-button
                  v-if="$can('creer-modifier-stock', 'vehicule')"
                  variant="primary"
                  :to="{ name: 'creer-vehicule'}"
              >
                Créer véhicule
              </b-button>
              <div class="align-self-center">{{pagination.total}} véhicules</div>
            </div>
            <!-- table -->
            <b-table v-if="items"
                     :fields="fields"
                     :items="items"
                     responsive

            >
              <template #cell(vehicule)="data">
                <div class="d-flex font-small-3">
                  <div class="mr-1">
                    <b-img thumbnail width="180" height="120" :src="data.item.thumbnail + '?w=180&h=120'" />
                  </div>
                  <div>
                    <div class="font-weight-bold">{{ data.item.marque ? data.item.marque.libelle : '' }} {{
                        data.item.modele ? data.item.modele.libelle : ''
                      }}
                    </div>
                    <div>{{ data.item.version ? data.item.version.libelle : '' }}</div>
                    <div>{{ data.item.immatriculation }} | {{ data.item.vin }}</div>
                    <div>{{ data.item.date_mec }} | {{ data.item.kilometrage }} KM</div>
                    <b-badge style="margin-bottom: 5px" :variant="data.item.publiable.variant">{{data.item.publiable.text}}</b-badge><br/>
                    <b-badge :variant="data.item.stock_date.jours > 90 ? 'danger' : 'success'">{{data.item.stock_date.text}}</b-badge>
                  </div>
                </div>
              </template>

              <template #cell(statut)="data">
                <b-badge variant="info">{{data.item.statut_vente ? data.item.statut_vente.libelle : ''}}</b-badge>
              </template>



              <template #cell(canaux_prix_annonce)="data">
                <div class="font-small-3 font-weight-bolder text-nowrap" v-if="data.item.canaux_prix_annonce.prix_vente_marchand_ttc" :class="data.item.canaux_prix_annonce.is_canal_vente_marchand_actif ? 'text-success' : 'text-danger'" >Marchands : {{data.item.canaux_prix_annonce.prix_vente_marchand_ttc.toLocaleString('fr')}} Dhs</div>
                <div class="font-small-3 font-weight-bolder text-nowrap" v-if="data.item.canaux_prix_annonce.prix_vente_particulier_ttc" :class="data.item.canaux_prix_annonce.is_canal_vente_particulier_actif ? 'text-success' : 'text-danger'">Particuliers : {{data.item.canaux_prix_annonce.prix_vente_particulier_ttc.toLocaleString('fr')}} Dhs</div>
              </template>

              <template #cell(actions)="data">
                <b-button
                    v-if="$can('creer-modifier-stock', 'vehicule')"
                    variant="flat-success"
                    class="btn-icon"
                    @click="$router.push({'name' : 'editer-vehicule', 'params' : {'id' : data.item.id}})"
                >
                  <feather-icon icon="EditIcon"/>
                </b-button>

              </template>
            </b-table>


          </b-card>
        </b-overlay>

      </b-col>
    </b-row>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
              v-model="pagination.current_page"
              :total-rows="pagination.total"
              :per-page="pagination.per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="fetch"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody, BCard, BCol, BRow
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import axios from "@/libs/axios";


export default {
  components: {
    BCardCode,
    BCard,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BCol,
    BRow
  },
  directives: {
    Ripple,
  },
  watch: {
    'pagination.current_page'(new_val, old_val) {
      this.fetch();
    }
  },
  data() {
    return {
      pendindExport : false,
      fields: [{
        'key': 'id',
      }, {
        'key': 'vehicule',
        'label': 'Véhicule'
      },
        {
          'key' : 'canaux_prix_annonce',
          'label' : 'Prix d\'annonce'
        }
      , {
        'key': 'created_at',
        'label': 'Date de création'
      }, 'statut', 'actions'],
      items: [],
      filter : {
        q : ''
      },
      selected: [],
      pagination: {
        current_page: 1
      },
      is_loading: false,

    }
  },
  created() {
    this.fetch(1);
  },

  methods: {
    exportHandler(){
      let _this = this;
      _this.pendindExport = true;
      axios.get(
          process.env.VUE_APP_API_URL + 'api/vehicules/stock?export=1&q=' + _this.filter.q,
          {responseType: 'blob'} // !!!
      ).then((response) => {
        _this.pendindExport = false;
        window.open(URL.createObjectURL(response.data));
      });
    },
    fetch(page) {
      let _this = this;
      if (_this.is_loading) {
        return false;
      }
      _this.is_loading = true;
      this.$store.dispatch('gestionStockModule/fetchStock', {
        page: page,
        ..._this.filter
      }).then(function (response) {
        _this.items = response.data;
        _this.pagination = response.meta;
        _this.is_loading = false;
      })
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}

</style>
